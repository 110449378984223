<template>
  <div
    class="datepicker-container hover:flex"
    v-if="openDatepicker"
  >
    <div
      class="wrapper"
      v-click-outside="close"
    >
      <div class="content">
        <date-picker
          class="datepicker"
          :range="true"
          :fillRange="true"
          :value="primaryDateRange"
          :zero-pad="false"
          :numerical-months="false"
          date-picker-style="primary"
          @inputRange="selectRange($event, 'primary')"
        ></date-picker>
        <div class="control-container p-4">
          <base-picker
            input-id="dateRange"
            input-name="dateRange"
            label="Date range"
            :items="['Yesterday', 'Last 7 days', 'Last 14 days', 'Last 30 days', 'Last month', 'Last 3 months']"
            :value="selectedDateRange"
            :item-label="item => item"
            :item-value="item => item"
            :error="null"
            :hide-feedback="true"
            placeholder="Select Range"
            @input="selectedDateRangePreset($event)"
          >
          </base-picker>
          <div class="flex flex-col gap-3 flex-1">
            <the-button
              class="relative"
              outer-class=" w-full"
              :overrideInnerStyle="{ 'justify-content': 'space-between' }"
              primary
              inverse
              @click.stop="selectDatePicker('btn-first-from')"
            >
              <template #label> From </template>
              <template #default>
                {{ primaryDateRange.from | formatDate }}
              </template>
              <template #icon>
                <ic-calendar-month />
              </template>
              <template #transition-container>
                <div
                  v-if="selectedDatepicker === 'btn-first-from'"
                  class="from hover:flex"
                >
                  <div class="bg-white border border-shadow-lg">
                    <date-picker
                      :close-on-click="true"
                      :date-picker-style="'primary'"
                      :value="primaryDateRange.from"
                      @close="selectDatePicker(null)"
                      @input="valuePicker($event, 'btn-first-from')"
                    ></date-picker>
                  </div>
                </div>
              </template>
            </the-button>
            <the-button
              class="relative"
              primary
              inverse
              @click.stop="selectDatePicker('btn-first-to')"
              outer-class=" w-full"
              :overrideInnerStyle="{ 'justify-content': 'space-between' }"
            >
              <template #label> To </template>
              <template #default>
                {{ primaryDateRange.to | formatDate }}
              </template>
              <template #icon>
                <ic-calendar-month />
              </template>
              <template #transition-container>
                <div
                  v-if="selectedDatepicker === 'btn-first-to'"
                  class="from hover:flex"
                >
                  <div class="bg-white border border-shadow-lg">
                    <date-picker
                      :close-on-click="true"
                      :date-picker-style="'primary'"
                      :value="primaryDateRange.to"
                      @close="selectDatePicker(null)"
                      @input="valuePicker($event, 'btn-first-to')"
                    ></date-picker>
                  </div>
                </div>
              </template>
            </the-button>
          </div>
          <div class="flex justify-end gap-3 mt-4">
            <the-button
              secondary
              @click="$emit('close')"
            >
              Cancel
            </the-button>
            <the-button
              primary
              @click="saveDateRange"
            >
              <template #default> Save date range </template>
              <template #icon>
                <ic-check :size="20" />
              </template>
            </the-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DatePicker from '@/components/date/DatePicker.vue'
import BasePicker from '@/components/input/base/BasePicker.vue'
import IcCalendarMonth from 'vue-material-design-icons/CalendarMonth'
import IcCheck from 'vue-material-design-icons/Check'
import { mapActions, mapGetters } from 'vuex'
import { dateRangePresets } from '@/utils/date'

export default {
  name: 'SearchTermsDateRange',
  components: { BasePicker, DatePicker, IcCalendarMonth, IcCheck },
  props: {
    openDatepicker: Boolean,
  },
  filters: {
    formatDate(date) {
      return date.toLocaleDateString()
    },
  },
  data() {
    return {
      selectedDatepicker: null,
      selectedDateRange: null,
    }
  },
  computed: {
    ...mapGetters({
      primaryDateRange: 'searchTermsOverview/primaryDateRange',
    }),
  },
  methods: {
    ...mapActions({
      getRangeDates: 'searchTermsOverview/getRangeDates',
      setRangeDates: 'searchTermsOverview/setRangeDates',
      getSearchTerms: 'searchTermsOverview/getSearchTerms',
    }),
    async selectRange({ from, to }, datepicker) {
      switch (datepicker) {
        case 'primary':
          await this.setRangeDates({ dateRange: { from, to }, propName: datepicker })
          break
        case 'secondary':
          await this.setRangeDates({ dateRange: { from, to }, propName: datepicker })
      }
    },
    async valuePicker(val, datePickerName) {
      switch (datePickerName) {
        case 'btn-first-from':
          await this.setRangeDates({
            dateRange: { ...this.primaryDateRange, from: val },
            propName: 'primary',
          })
          break
        case 'btn-first-to':
          await this.setRangeDates({
            dateRange: { ...this.primaryDateRange, to: val },
            propName: 'primary',
          })
          break
      }
    },
    selectDatePicker(datepicker) {
      this.selectedDatepicker = datepicker
    },
    async selectedDateRangePreset(dateRange) {
      this.selectedDateRange = dateRange
      const ranges = dateRangePresets(this.selectedDateRange)
      if (ranges) {
        await this.setRangeDates({
          dateRange: { ...this.primaryDateRange, ...ranges },
          propName: 'primary',
        })
      }
    },
    close() {
      if (this.openDatepicker) {
        this.$emit('close')
      }
    },
    async saveDateRange() {
      this.$emit('close')
      await this.$router.push({ query: null })
      await this.getSearchTerms({ query: { ...this.$route.query, page: 1 }, addTotal: false })
    },
  },
}
</script>

<style scoped lang="scss">
.datepicker-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, $overlay__opacity);
  z-index: 6999;
  overflow-y: auto;

  .wrapper {
    @apply bg-white  border  shadow-lg mt-12;
    width: fit-content;

    .content {
      @apply flex flex-col-reverse;
      .datepicker {
        @apply self-center;
      }

      .control-container {
        @apply bg-bb-pale-grey flex flex-col  gap-3;
        width: 302px;

        .from {
          @apply absolute mt-1 z-50;
        }
      }
    }
  }
}

@screen lg {
  .datepicker-container {
    @apply absolute z-50 mt-2;
    transform: translate(31%);
    right: 0;
    top: unset;
    bottom: unset;
    left: unset;
    background-color: rgba(0, 0, 0, 0);
    overflow-y: unset;

    .wrapper {
      @apply bg-white  border  shadow-lg mt-0;

      .content {
        @apply flex flex-row gap-3;

        .control-container {
          @apply bg-bb-pale-grey flex flex-col  gap-3;
          width: 302px;

          .from {
            @apply absolute mt-1 z-50;
            left: 50%;
            transform: translate(-50%);
          }
        }
      }
    }
  }
}
</style>
