<template>
  <page>
    <page-section bottom-border>
      <page-title>
        <template #icon>
          <ic-text-search-variant :size="32" />
        </template>
        Search terms
      </page-title>
    </page-section>
    <page-section v-if="!proStore">
      <upgrade-subscription>
        <template #content>
          This report is only available for paying subscription. You can test this feature during 30 days for FREE.
        </template>
      </upgrade-subscription>
    </page-section>
    <page-section v-else-if="proStore && !account.sales_led && !mainSteps">
      <base-alert type="information">
        <template #title> Information </template>
        <template #content> Complete the onboarding to get started with Bidbrain </template>
        <template #action>
          <the-button
            @click="$router.push({ name: 'dashboard' })"
            information
          >
            Complete&nbsp;onboarding
            <template #icon>
              <ic-chevron-right :size="16" />
            </template>
          </the-button>
        </template>
      </base-alert>
    </page-section>
    <div v-else>
      <page-section bottom-border>
        <div v-if="!overallLoad">
          <loader-ellipsis class="text-bb-neon-purple transform" />
          Load search terms
        </div>
        <hero v-if="overallLoad">
          <template #image>
            <img
              src="/content/images/illustrations/green-stats.svg"
              alt="Let Bidbrain do the heavy lifting"
              class="sofa-guy"
            />
          </template>
          <template #title> Explore your search terms </template>
          <template #description>
            Find out exactly what shoppers search for to find your products, get to know which terms and keywords
            convert and which doesn’t.
          </template>
          <template #links>
            <bid-link
              :router-link="false"
              primary
              target="_blank"
              href-link="https://academy.bidbrain.com/faq"
            >
              <template #text> FAQ </template>
            </bid-link>
            <bid-link
              :router-link="false"
              primary
              target="_blank"
              href-link="https://academy.bidbrain.com/how-to-create-a-bidbrain-shopping-campaign"
            >
              <template #text> Our Guides </template>
            </bid-link>
            <bid-link
              :router-link="false"
              primary
              target="_blank"
              href-link="https://blog.bidbrain.com"
            >
              <template #text> Blog & Articles </template>
            </bid-link>
          </template>
        </hero>
      </page-section>
      <div
        ref="search_terms"
        v-if="searchTerms && overallLoad"
      >
        <page-section
          bottom-border
          full-width
        >
          <search-terms-filters :total-products="searchTerms.pagination.total_search_terms"></search-terms-filters>
        </page-section>
        <page-section
          bottom-border
          full-width
        >
          <search-terms-table-actions
            :total-products="searchTerms.pagination.total_search_terms"
            :selected-all="selectAll"
            :selected-items="selected"
          ></search-terms-table-actions>
        </page-section>
        <data-table
          v-if="searchTerms.search_terms.length"
          :loading="tableLoading"
          :items="searchTerms.search_terms"
          :headers="headers"
          has-check-box
          :footer-items="searchTerms.aggregations"
          :column-defs="columnDefs"
          @selectAll="selectAllItems"
          @sort="sortHeader"
          @selected="selected = $event"
        >
          <template #search-term="{ value }">
            <span>{{ value }}</span>
          </template>
          <template #tfoot-search-term>
            Product search terms in filter: {{ searchTerms.pagination.total_search_terms }}
          </template>
          <template #quality="{ value }">
            <quality-bars :value="value" />
          </template>
          <template #impressions="{ value }">
            <span>{{ value }}</span>
          </template>
          <template #tfoot-impressions="{ items }">
            <span>{{ items.sum_impressions.value }}</span>
          </template>
          <template #ctr="{ value }">
            <span>{{ value | toFixed(2) }}</span>
          </template>
          <template #clicks="{ value }">
            <span>{{ value }}</span>
          </template>
          <template #tfoot-clicks="{ items, header }">
            <span>{{ items[header.footerAccessibility].value }}</span>
          </template>
          <template #cpc="{ value }">
            <span>{{ value | toFixed(2) | prepend(dynamicCurrency) }}</span>
          </template>
          <template #tfoot-cpc="{ items, header }">
            <span>{{ items[header.footerAccessibility].value | toFixed(2) | prepend(dynamicCurrency) }}</span>
          </template>
          <template #conv-value="{ value }">
            <span>{{ value | toFixed(2) | prepend(dynamicCurrency) }}</span>
          </template>
          <template #tfoot-conv-value="{ items, header }">
            <span>{{ items[header.footerAccessibility].value | toFixed(2) | prepend(dynamicCurrency) }}</span>
          </template>
          <template #cost="{ value }">
            <span>{{ value | toFixed(2) | prepend(dynamicCurrency) }}</span>
          </template>
          <template #tfoot-cost="{ items, header }">
            <span>{{ items[header.footerAccessibility].value | toFixed(2) | prepend(dynamicCurrency) }}</span>
          </template>
          <template #roas="{ value }">
            <span>{{ value | toFixed(2) }}</span>
          </template>
          <template #tfoot-roas="{ items, header }">
            <span>{{ items[header.footerAccessibility].value | toFixed(2) }}</span>
          </template>
          <template #conversions="{ value }">
            <span>{{ value | toFixed(2) }}</span>
          </template>
          <template #tfoot-conversions="{ items, header }">
            <span>{{ items[header.footerAccessibility].value | toFixed(2) }}</span>
          </template>
          <template #header-profit="{ header }">
            <tooltip
              :id="header.slot_name"
              direction="left"
            >
              <template #title>
                <span>{{ header.header }}</span>
              </template>
              <template>
                <span>Profit = Conversion Value - Cost</span>
              </template>
            </tooltip>
          </template>
          <template #profit="{ value }">
            <span>{{ value | toFixed(2) | prepend(dynamicCurrency) }}</span>
          </template>
          <template #tfoot-profit="{ items, header }">
            <span>{{ items[header.footerAccessibility].value | toFixed(2) | prepend(dynamicCurrency) }}</span>
          </template>

          <template #pagination>
            <div class="flex flex-wrap justify-between items-center gap-5">
              <page-size
                :items="['50', '100', '250']"
                :default-limit="50"
                :default-page="1"
              ></page-size>
              <pagination
                @onChange="scrollToTop"
                v-if="searchTerms && searchTerms.pagination"
                :max="searchTerms.pagination.total_search_terms"
                show-first-and-last
                :page="$route.query.page ? +$route.query.page : defaultPage"
                :limit="$route.query.limit ? +$route.query.limit : defaultLimit"
              />
            </div>
          </template>
        </data-table>
        <shape
          slot-full-width
          v-else
        >
          <base-alert
            v-if="!tableLoading"
            type="information"
          >
            <template #title> Fetching search terms </template>
            <template #content>
              Check back soon, we are fetching and generating insights from your search terms. This can take up to 48
              hours.
            </template>
          </base-alert>
          <div v-else>
            <loader-ellipsis class="text-bb-neon-purple transform" />
            Load search terms
          </div>
        </shape>
      </div>
    </div>
  </page>
</template>

<script>
import Page from '@/components/base/page/Page.vue'
import PageSection from '@/components/base/page/PageSection.vue'
import IcTextSearchVariant from '@/components/icon/ic-text-search-variant.vue'
import Hero from '@/components/shared/Hero.vue'
import BidLink from '@/components/base/BidLink.vue'
import { mapActions, mapGetters } from 'vuex'
import DataTable from '@/components/table/DataTable.vue'
import PageSize from '@/components/table/PageSize.vue'
import Pagination from '@/components/table/Pagination.vue'
import QualityBars from '@/components/search-terms/QualityBars.vue'
import SearchTermsTableActions from '@/components/search-terms/overview/SearchTermsTableActions.vue'
import Tooltip from '@/components/alert/Tooltip.vue'
import LoaderEllipsis from '@/components/loader/EllipsisLoader.vue'
import SearchTermsFilters from '@/components/search-terms/overview/filter/SearchTermsFilters.vue'
import BaseAlert from '@/components/alert/BaseAlert.vue'
import UpgradeSubscription from '@/components/alert/UpgradeSubscription.vue'
import IcChevronRight from 'vue-material-design-icons/ChevronRight'

export default {
  name: 'search-terms',
  components: {
    UpgradeSubscription,
    BaseAlert,
    SearchTermsFilters,
    LoaderEllipsis,
    Tooltip,
    SearchTermsTableActions,
    QualityBars,
    Pagination,
    PageSize,
    DataTable,
    BidLink,
    Hero,
    IcTextSearchVariant,
    PageSection,
    Page,
    IcChevronRight,
  },
  data() {
    return {
      selected: [],
      selectAll: null,
    }
  },
  filters: {
    toFixed(value, fixed) {
      if (!value) return value
      return Number(value).toFixed(fixed)
    },
    concat(value, string) {
      if (!value) return value
      return value.toString().concat(string)
    },
    prepend(value, string) {
      if (!value) return value
      return string + ' ' + value
    },
  },
  watch: {
    async '$route.query'(val) {
      if (this.overallLoad) {
        if (val.hasOwnProperty('page') || val.hasOwnProperty('limit')) {
          await this.getSearchTerms({
            query: this.$route.query,
            addTotal: true,
          })
        }
      }
    },
    async storeId() {
      await this.setOverallLoad(false)
      await this.clearOnChangeStore()
      await this.loadData()
    },
  },
  computed: {
    ...mapGetters({
      store: 'store/selectedStore',
      account: 'account/getAccount',
      storeId: 'store/selectedStoreId',
      overallLoad: 'searchTermsOverview/overAllLoad',
      tableLoading: 'searchTermsOverview/tableLoading',
      searchTerms: 'searchTermsOverview/searchTerms',
      headers: 'searchTermsOverview/headers',
      columnDefs: 'searchTermsOverview/columnDefs',
      defaultPage: 'searchTermsOverview/defaultPage',
      defaultLimit: 'searchTermsOverview/defaultLimit',
      currency: 'currency/getCurrencyBySelectedStore',
      mainSteps: 'onBoardingForm/mainStepCompleted',
    }),
    proStore() {
      if (this.store.subscription_slug === 'pro' || this.store.subscription_slug === 'pro_plus') {
        return true
      }
    },
    dynamicCurrency() {
      if (this.currency.id == '39' || this.currency.id == '42' || this.currency.id == '132') {
        return this.currency.symbol || 'N/A'
      } else {
        return this.currency.code || 'N/A'
      }
    },
  },
  methods: {
    ...mapActions({
      getSearchTerms: 'searchTermsOverview/getSearchTerms',
      sort: 'searchTermsOverview/sort',
      loadCurrencies: 'currency/loadCurrenciesIfNeeded',
      getFilters: 'searchTermsOverview/getFilters',
      addFilters: 'searchTermsOverview/addFilters',
      getRangeDates: 'searchTermsOverview/getRangeDates',
      loadCampaigns: 'campaign/loadCampaignsByStoreIdIfNeeded',
      clearOnChangeStore: 'searchTermsOverview/clearOnChangeStore',
      setOverallLoad: 'searchTermsOverview/setOverallLoad',
    }),
    scrollToTop(pager) {
      this.$router.push({
        query: {
          ...this.$route.query,
          page: pager.current_page,
        },
      })
      this.$nextTick(() => {
        seamless.scrollIntoView(this.$refs.search_terms, {
          behavior: 'smooth',
        })
      })
    },
    selectAllItems(val) {
      switch (val.state) {
        case null:
        case true:
          this.selectAll = true
          break
        case false:
          this.selectAll = false
          break
      }
    },
    async sortHeader(val) {
      await this.sort(val)
      await this.getSearchTerms({ query: this.$route.query, addTotal: true })
    },
    async loadData() {
      if (this.proStore && this.mainSteps) {
        await this.getRangeDates()
        await this.getFilters()
        await this.loadCurrencies()
        await this.loadCampaigns(this.storeId)
        await this.getSearchTerms({
          query: this.$route.query,
          addTotal: false,
        })
      }
    },
  },
  async mounted() {
    await this.loadData()
  },
  destroyed() {
    this.setOverallLoad(false)
  },
}
</script>
