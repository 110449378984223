<template>
  <div
    :class="{ [value.toLowerCase()]: value }"
    class="quality"
  >
    <div class="vertical-lines">
      <div class="line-1"></div>
      <div class="line-2"></div>
      <div class="line-3"></div>
    </div>
    <span>{{ value }}</span>
  </div>
</template>

<script>
export default {
  name: 'QualityBars',
  props: {
    value: String,
  },
}
</script>

<style scoped lang="scss">
.quality {
  @apply flex gap-2 items-baseline;
  .vertical-lines {
    @apply flex;
    gap: 0.2rem;
    transform: rotate(180deg);
    width: fit-content;

    div {
      &:first-child {
        border-left: 2px solid;
        @apply border-bb-cool-grey;
        height: 15px;
      }

      &:nth-child(2) {
        border-left: 2px solid;
        @apply border-bb-cool-grey;
        height: 10px;
      }

      &:last-child {
        border-left: 2px solid;
        @apply border-bb-cool-grey;
        height: 5px;
      }
    }
  }

  &.high {
    @apply text-bb-dull-green;
    .vertical-lines div {
      @apply border-bb-dull-green;
    }
  }

  &.medium {
    @apply text-bb-dull-orange;
    .vertical-lines div {
      &:last-child {
        @apply border-bb-dull-orange;
      }

      &:nth-child(2) {
        @apply border-bb-dull-orange;
      }
    }
  }
  &.low {
    @apply text-bb-dull-red;
    .vertical-lines div {
      &:last-child {
        @apply border-bb-dull-red;
      }
    }
  }
}
</style>
