<template>
  <div class="filter-wrapper">
    <div class="flex flex-wrap justify-between">
      <div class="upper-filter">
        <h2>Filters</h2>
      </div>
      <div class="chip-filter">
        <strong v-if="pureFiltersArray.length">{{ totalProducts }} products match your filters</strong>
        <div class="flex gap-3">
          <the-button
            @click.stop="openDatepicker = true"
            chip
            :override-inner-style="innerClassOverride"
            :override-outer-style="outerClassOverride"
            class="relative"
          >
            <label class="font-medium cursor-pointer">{{ formatPrimaryRangeDate }}</label>
            <template #icon>
              <label class="cursor-pointer">
                <ic-calendar-month :size="18" />
              </label>
            </template>
            <template #transition-container>
              <search-terms-date-range
                v-if="openDatepicker"
                :open-datepicker="openDatepicker"
                @close="openDatepicker = !openDatepicker"
              ></search-terms-date-range>
            </template>
          </the-button>
          <base-picker
            input-id="campaign"
            input-name="campaign"
            :items="getCampaigns(storeId)"
            :value="selectedCampaign"
            :item-label="item => item.name"
            :item-value="item => item.name"
            :error="null"
            :hide-feedback="true"
            :remove-radius="true"
            :unselect="'All campaigns'"
            placeholder="Bidbrain AI campaign"
            @input="setFilterByCampaign"
          >
            <template #customIcon>
              <ic-tag :size="18" />
            </template>
          </base-picker>
        </div>
      </div>
    </div>
    <filters
      :metrics="metrics"
      :filters="searchTermsFilters"
      @addFilter="addFilter"
      @deleteFilter="deleteFilter"
      @editFilter="addFilter"
      @clearAll="clearAll"
    ></filters>
  </div>
</template>

<script>
import Filters from '@/components/table/Filters.vue'
import { mapActions, mapGetters } from 'vuex'
import BasePicker from '@/components/input/base/BasePicker.vue'
import IcCalendarMonth from 'vue-material-design-icons/CalendarMonth'
import IcTag from 'vue-material-design-icons/Tag'
import dayjs from 'dayjs'
import SearchTermsDateRange from '@/components/search-terms/overview/filter/SearchTermsDateRange.vue'

export default {
  name: 'SearchTermsFilters',
  components: { SearchTermsDateRange, BasePicker, Filters, IcCalendarMonth, IcTag },
  props: {
    totalProducts: Number,
  },
  data() {
    return {
      openDatepicker: null,
      metrics: [
        { name: 'Search term', value: 'search_term', type: 'text', prefix: null },
        { name: 'Clicks', value: 'clicks', type: 'number', prefix: null },
        { name: 'Cost', value: 'cost', type: 'number', prefix: 'currency' },
        { name: 'Avg. CPC', value: 'cpc', type: 'number', prefix: 'currency' },
        { name: 'Impressions', value: 'impressions', type: 'number', prefix: null },
        { name: 'CTR', value: 'ctr', type: 'number', prefix: null },
        { name: 'Conv. value', value: 'conv_value', type: 'number', prefix: 'currency' },
        { name: 'ROAS', value: 'roas', type: 'number', prefix: 'percentage' },
        { name: 'Conversions', value: 'conversions', type: 'number', prefix: null },
        { name: 'Profit', value: 'profit', type: 'number', prefix: 'currency' },
        { name: 'Conv. value / Click', value: 'cvpc', type: 'number', prefix: 'currency' },
      ],
    }
  },
  computed: {
    ...mapGetters({
      storeId: 'store/selectedStoreId',
      searchTermsFilters: 'searchTermsOverview/filters',
      getCampaigns: 'campaign/getCampaignsByStoreId',
      primaryRangeDate: 'searchTermsOverview/primaryDateRange',
      selectedCampaign: 'searchTermsOverview/selectedCampaign',
    }),
    outerClassOverride() {
      return { 'border-radius': 0, padding: 0 }
    },
    innerClassOverride() {
      return { paddingTop: '0.5rem', paddingBottom: '0.5rem' }
    },
    pureFiltersArray() {
      return this.searchTermsFilters.filter(filter => filter.id !== 0)
    },
    formatPrimaryRangeDate() {
      if (this.primaryRangeDate) {
        const from = dayjs(this.primaryRangeDate.from).format('YYYY/MM/DD')
        const to = dayjs(this.primaryRangeDate.to).format('YYYY/MM/DD')
        return `${from}-${to}`
      }
      return ''
    },
  },
  methods: {
    ...mapActions({
      addSearchTermFilter: 'searchTermsOverview/addFilters',
      deleteSearchTermFilter: 'searchTermsOverview/deleteFilter',
      clearAllSearchTermFilters: 'searchTermsOverview/clearAllFilters',
      getSearchTerms: 'searchTermsOverview/getSearchTerms',
      setCampaign: 'searchTermsOverview/setCampaign',
    }),
    async setFilterByCampaign(name) {
      await this.setCampaign(name)
      await this.getSearchTerms({ query: { ...this.$route.query, page: 1 }, addTotal: false })
      await this.$router.push({ query: null })
    },
    async addFilter(filter) {
      await this.addSearchTermFilter(filter)
      if (filter.id !== 0) {
        await this.getSearchTerms({ query: { ...this.$route.query, page: 1 }, addTotal: false })
        await this.$router.push({ query: null })
      }
    },
    async deleteFilter(filter) {
      await this.deleteSearchTermFilter(filter)
      if (filter.id !== 0) {
        await this.getSearchTerms({ query: { ...this.$route.query, page: 1 }, addTotal: false })
      }
      await this.$router.push({ query: null })
    },
    async clearAll() {
      await this.clearAllSearchTermFilters()
      await this.getSearchTerms({ query: { ...this.$route.query, page: 1 }, addTotal: false })
      await this.$router.push({ query: null })
    },
  },
}
</script>

<style scoped lang="scss">
.filter-wrapper {
  @apply flex flex-col gap-8;

  .upper-filter {
    @apply flex  flex-wrap gap-3 justify-between items-center mr-2;
    > h2 {
      @apply w-full;
    }

    > b {
      @apply mb-3;
    }
  }

  .chip-filter {
    @apply flex flex-wrap gap-3 items-center;
  }

  .filters-container {
    @apply absolute mt-1 z-10;
    width: 90.5%;
  }

  .buttons-wrapper {
    @apply grid grid-cols-1 gap-3;
  }

  @screen md {
    .buttons-wrapper {
      @apply flex flex-wrap gap-3;
    }

    .filters-container {
      @apply absolute mt-2 z-10;
      width: fit-content;
    }

    .upper-filter {
      @apply flex flex-wrap gap-3 flex-1 justify-between items-center mr-2;
    }
  }

  @screen lg {
    .filters-container {
      @apply absolute mt-2 z-10;
      width: 750px;
    }
  }
}
</style>
