<template>
  <div
    class="tooltip"
    :style="cssVars"
    @mouseenter="calculateContainer"
  >
    <slot
      name="title"
      v-if="hasTitle"
    />
    <component
      v-if="!hasTitle && dynamicIcon"
      :is="dynamicIcon.component"
      :class="dynamicIcon.class"
      :size="+size"
    />
    <div
      class="tooltip-container"
      v-for="pos in positions"
      :ref="id"
      :class="pos"
    >
      <slot />
      <em :style="arrow"></em>
    </div>
  </div>
</template>

<script>
import icInfo from 'vue-material-design-icons/Information'
import icAlertCircle from 'vue-material-design-icons/AlertCircle'
import icCheck from 'vue-material-design-icons/CheckCircle'

export default {
  name: 'tooltip',
  components: { icInfo, icAlertCircle, icCheck },
  data() {
    return {
      positions: ['top', 'bottom', 'right', 'left'],
      arrow: null,
      tooltipContainer: null,
      backgroundColor: null,
      color: null,
      tooltipDirection: null,
      show: false,
    }
  },

  props: {
    id: {
      type: String,
      required: true,
    },
    parentId: {
      type: String,
    },
    direction: {
      type: String,
      default: 'bottom',
      validator: propValue => ['top', 'bottom', 'right', 'left'].includes(propValue),
    },
    arrowLocation: {
      type: String,
      default: '50',
    },
    maxWidth: {
      type: String,
      default: '300',
    },
    type: {
      type: String,
      default: 'info',
      validator: propValue => ['info', 'warning', 'success'].includes(propValue),
    },
    size: {
      type: String,
      default: '16',
    },
    minWidth: {
      type: String,
      default: '0',
    },
    textColor: {
      type: String,
    },
    bgColor: {
      type: String,
    },
  },
  computed: {
    hasTitle() {
      return !!this.$slots['title']
    },
    dynamicIcon() {
      switch (this.type) {
        case 'info':
          return { component: 'ic-info', class: 'text-bb-dull-blue info' }
        case 'warning':
          return { component: 'ic-alert-circle', class: 'text-bb-dull-orange' }
        case 'success':
          return { component: 'ic-check', class: 'text-bb-dull-green' }
      }
    },
    cssVars() {
      return {
        '--color': this.color,
        '--background-color': this.backgroundColor,
        '--arrow-location': this.arrow,
        '--max-width': this.maxWidth,
        '--min-width': this.minWidth,
      }
    },
  },
  methods: {
    calculateContainer() {
      this.$nextTick(() => {
        this.calculateStyling()
        const parentContainer = this.parentId
          ? document.getElementById(this.parentId)
          : document.querySelector('.l-console')
        const foundRef = this.$refs[this.id].find(ref => ref.classList.contains(this.direction))
        if (foundRef) {
          const initElementInViewPort = this.isElementInViewport(parentContainer, foundRef)

          if (initElementInViewPort) {
            this.$refs[this.id].forEach(ref => {
              if (ref !== foundRef) {
                ref.style.display = 'none'
              }
            })
          } else {
            let firstRefOnViewPort
            for (let i = 0; i < this.$refs[this.id].length; i++) {
              if (!this.isElementInViewport(parentContainer, this.$refs[this.id][i])) {
                this.$refs[this.id][i].style.display = 'none'
              } else {
                firstRefOnViewPort = this.$refs[this.id][i]
                break
              }
            }
            if (firstRefOnViewPort) {
              this.$refs[this.id].forEach(ref => {
                if (ref !== firstRefOnViewPort) {
                  ref.style.display = 'none'
                }
              })
            }
          }
        }
      })
    },
    isElementInViewport(par, el) {
      const elRect = el.getBoundingClientRect()
      const parRect = par.getBoundingClientRect()

      const isFullyVisible =
        elRect.x >= parRect.x &&
        elRect.y >= parRect.y &&
        elRect.x + elRect.width <= parRect.x + parRect.width &&
        elRect.y + elRect.height <= parRect.y + parRect.height
      return (
        elRect.top >= parRect.top &&
        elRect.left >= parRect.left &&
        elRect.bottom <= parRect.bottom &&
        elRect.right <= parRect.right &&
        isFullyVisible
      )
    },
    calculateStyling() {
      if (this.textColor && this.bgColor) {
        this.color = this.textColor
        this.backgroundColor = this.bgColor
      } else {
        switch (this.type) {
          case 'info':
            this.backgroundColor = '#CBEBFB'
            this.color = '#1082A9'
            break
          case 'warning':
            this.backgroundColor = '#FFEEBB'
            this.color = '#DC7828'
            break
          case 'success':
            this.backgroundColor = '#DFF1DA'
            this.color = '#35AC73'
        }
      }
    },
  },
  mounted() {
    this.arrow = this.arrowLocation
  },
}
</script>
<style lang="scss" scoped>
$negative-location: calc(var(--arrow-location) * var(--u, 1%) * -1);
$positive-location: calc(var(--arrow-location) * var(--u, 1%));
$max-width: calc(var(--max-width) * 1px);
$min-width: calc(var(--min-width) * 1px);

.tooltip {
  display: inline-block;
  position: relative;

  .info {
    cursor: help;
  }

  .tooltip-container {
    color: var(--color);
    background-color: var(--background-color);
    font-weight: normal;
    font-size: 13px;
    position: absolute;
    z-index: 99999999999;
    box-sizing: border-box;
    box-shadow: 0 1px 8px transparent;
    visibility: hidden;
    opacity: 0;
    max-width: $max-width;
    min-width: $min-width;
    width: max-content;
    transition: opacity 0.3s;
    padding: 10px 10px;
    white-space: pre-wrap;

    &.right {
      top: 50%;
      left: 100%;
      margin-left: 10px;
      padding: 10px 10px;
      transform: translate(0, $negative-location);

      em {
        position: absolute;
        right: 100%;
        top: $positive-location;
        margin-top: -12px;
        width: 12px;
        height: 24px;
        overflow: hidden;

        &:after {
          content: '';
          position: absolute;
          width: 12px;
          height: 12px;
          left: 0;
          top: 50%;
          transform: translate(50%, -50%) rotate(-45deg);
          background-color: var(--background-color);
          box-shadow: 0 1px 8px transparent;
        }
      }
    }

    &.top {
      top: -10px;
      left: 50%;
      transform: translate($negative-location, -100%);

      em {
        position: absolute;
        top: 100%;
        left: $positive-location;
        margin-left: -12px;
        width: 24px;
        height: 12px;
        overflow: hidden;

        &:after {
          content: '';
          position: absolute;
          width: 12px;
          height: 12px;
          left: 50%;
          transform: translate(-50%, -50%) rotate(45deg);
          background-color: var(--background-color);
          border: 1px solid transparent;
          box-shadow: 0 1px 8px transparent;
        }
      }
    }

    &.bottom {
      top: 30px;
      left: 50%;
      transform: translate($negative-location, 0);

      em {
        position: absolute;
        bottom: 100%;
        left: $positive-location;
        margin-left: -12px;
        width: 24px;
        height: 12px;
        overflow: hidden;

        &:after {
          content: '';
          position: absolute;
          width: 12px;
          height: 12px;
          left: 50%;
          transform: translate(-50%, 50%) rotate(45deg);
          background-color: var(--background-color);
          border: 1px solid transparent;
          box-shadow: 0 1px 8px transparent;
        }
      }
    }

    &.left {
      top: 50%;
      right: 100%;
      margin-right: 10px;
      transform: translate(0, $negative-location);

      em {
        position: absolute;
        top: $positive-location;
        left: 100%;
        margin-top: -12px;
        width: 12px;
        height: 24px;
        overflow: hidden;

        &:after {
          content: '';
          position: absolute;
          width: 12px;
          height: 12px;
          left: 0;
          top: 50%;
          transform: translate(-50%, -50%) rotate(-45deg);
          background-color: var(--background-color);
          border: 1px solid transparent;
          box-shadow: 0 1px 8px transparent;
        }
      }
    }
  }

  &:hover {
    .right,
    .top,
    .bottom,
    .left {
      visibility: visible;
      opacity: 1;
    }
  }
}
</style>
